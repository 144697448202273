import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Select } from 'antd';
import _, { isSet } from 'lodash';

function TransactionRate(props) {
	const { services, rates, onUpdateData } = props;

	const [columns, setColumns] = useState([
		{
			title: 'Payment modes',
			render: (text, record) => (
				<strong>{record.name}</strong>
			)
		}
	]);
	const [items, setItems] = useState([]);

	useEffect(() => {
		const serviceColumns = services.map(service => {
			return {
				title: service.name,
				render: (text, record) => (
					<Input
						defaultValue={record[service.name].value}
						onChange={(e) => onUpdateData(`rate.${record.index}.data.${service.id}.data.value`, e.target.value)}
						addonAfter={
							<Select
								defaultValue={record[service.name].type}
								options={[
									{ label: '%', value: 'percent' },
									{ label: 'INR', value: 'fixed' },
								]}
								onChange={(value) => onUpdateData(`rate.${record.index}.data.${service.id}.data.type`, value)}
							/>
						}
					/>
				)
			}
		});

		setColumns([...columns, ...serviceColumns]);
	}, []);

	useEffect(() => {
		const items = [];
		Object.keys(rates).forEach(key => {
			let item = {
				index: key,
				name: rates[key].name
			}
			// Object.values(rates[key].data).forEach(elem => {
			// 	item[elem.name] = elem?.data?.value || 0;
			// });

			// don't use string key 
			const innerKeys = Object.keys(rates[key].data).filter(i => Number(i));
			innerKeys.forEach(innerKey => {
				if (rates[key].data[innerKey]?.data) {
					item[rates[key].data[innerKey].name] = rates[key].data[innerKey]?.data;
				} else {
					item[rates[key].data[innerKey].name] = {
						type: 'percent',
						value: 0
					}
				}
			})

			items.push(item);
		});
		setItems(items);
	}, [rates]);

	return (
		<div>
			<p>
				You are required to set the transaction rates approved for your account by the payment gateway. By
				default normal transaction rates will be auto filled for each enabled gateway. If you have applied for
				the gateways via our platform, then the discounted transaction rates will apply automatically.
			</p>
			<Table
				rowKey={'index'}
				dataSource={items}
				columns={columns}
				pagination={false}
			/>
		</div>
	);
}

export default TransactionRate;
