import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Typography, Input, Card, Tooltip } from "antd";
import PageTitle from 'components/PageTitle';
import { LinkIcon } from '@heroicons/react/outline';
// request
import { verifyDomain } from 'requests/domain';
import { generateDomainStatus } from 'utils/common';
import regex from 'utils/regex';

const { Title } = Typography;

const LiveChat = () => {
    const [loading, setLoading] = useState(true);
    const [iframeUrl, setIframeUrl] = useState('');

    const authUser = useSelector(state => state.auth.authUser);

    const location = useLocation();

    const titles = [
        { path: '#', title: 'Notifications' },
        { path: location.pathname, title: 'Live Chat' }
    ];

    useEffect(() => {
        const chatIframeUrl = authUser.config?.notification?.whatsapp?.settings?.chat_iframe;
        setIframeUrl(chatIframeUrl);
        setLoading(false);
    }, [authUser]);

    return (
        <div className="mb-36">
            <PageTitle titles={titles} />
            <div className='iframe-wrapper'>
                <iframe width='100%' height='100%' src={iframeUrl} frameborder="0" scrolling="yes" />
            </div>
        </div>
    )
}

export default LiveChat;