import { Button, Table, Tag } from 'antd';
import { parse, stringify } from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { FaPause, FaPlay, FaStop } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import PageTitle from '../../components/PageTitle/index.js';
import { cancelCampaign, continueCampaign, getCampaigns, pauseCampaign } from '../../requests/notification.js';

export default function Campaigns() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentQuery, setCurrentQuery] = useState({
        page: 1,
        per_page: 10,
    });

    const location = useLocation();
    const navigate = useNavigate();

    const enumStatuses = useSelector((state) => state.config.campaign_status);

    const [statuses, statusColors] = useMemo(() => {
        const statuses = {};
        const statusColors = {};
        for (const status of enumStatuses) {
            statuses[status.value] = status.display;
            statusColors[status.value] = status.color;
        }
        return [statuses, statusColors];
    }, [enumStatuses]);

    useEffect(() => {
        getData();
    }, [location.search]);

    const getData = async () => {
        try {
            setLoading(true);

            const query = parse(location.search.slice(1));

            setCurrentQuery(query);

            const data = await getCampaigns(query);
            setData(data);
        } catch (err) {
            toast.error(err.response?.data?.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleQuery = (newQuery) => {
        const query = {
            ...currentQuery,
            ...newQuery,
        };

        navigate({
            pathname: location.pathname,
            search: stringify(query),
        });
    };

    const handlePageChange = (page, per_page) => {
        handleQuery({ page, per_page });
    };

    const handlePauseCampaign = async (id) => {
        try {
            setLoading(true);
            await pauseCampaign(id);
            await getData();
        } catch (err) {
            toast.error(err.response?.data?.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleContinueCampaign = async (id) => {
        try {
            setLoading(true);
            await continueCampaign(id);
            await getData();
        } catch (err) {
            toast.error(err.response?.data?.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCancelCampaign = async (id) => {
        try {
            setLoading(true);
            await cancelCampaign(id);
            await getData();
        } catch (err) {
            toast.error(err.response?.data?.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (id) => <Link to={`/notifications/campaigns/${id}`}>Campaign#{id}</Link>,
        },
        {
            title: 'Sent',
            dataIndex: 'sent',
            width: '8%',
            render: (value, record) => <Link to={`/notifications/campaigns/${record.id}?status=1,4`}>{value}</Link>,
        },
        {
            title: 'Delivered',
            dataIndex: 'delivered',
            width: '8%',
            render: (value, record) => <Link to={`/notifications/campaigns/${record.id}?status=6`}>{value}</Link>,
        },
        {
            title: 'Read',
            dataIndex: 'read',
            width: '8%',
            render: (value, record) => <Link to={`/notifications/campaigns/${record.id}?status=5`}>{value}</Link>,
        },
        {
            title: 'Scheduled',
            dataIndex: 'scheduled',
            width: '8%',
            render: (value, record) => <Link to={`/notifications/campaigns/${record.id}?status=3`}>{value}</Link>,
        },
        {
            title: 'Failed',
            dataIndex: 'failed',
            width: '8%',
            render: (value, record) => <Link to={`/notifications/campaigns/${record.id}?status=2,8`}>{value}</Link>,
        },
        {
            title: 'All',
            dataIndex: 'all',
            width: '8%',
            render: (value, record) => <Link to={`/notifications/campaigns/${record.id}`}>{value}</Link>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '10%',
            render: (value, record) => <Tag color={statusColors[value]}>{statuses[value] || value}</Tag>,
        },
        {
            title: 'Action',
            width: '10%',
            className: 'action',
            render: (record) => (
                <div className="nowrap">
                    {record.status === 1 && (
                        <Button>
                            <FaPause onClick={() => handlePauseCampaign(record.id)} />
                        </Button>
                    )}
                    {record.status === 3 && (
                        <Button>
                            <FaPlay onClick={() => handleContinueCampaign(record.id)} />
                        </Button>
                    )}
                    {record.status !== 2 && record.status !== 4 && (
                        <Button>
                            <FaStop onClick={() => handleCancelCampaign(record.id)} />
                        </Button>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div className="notification-campaigns">
            <div className="header mb-16">
                <PageTitle
                    titles={[
                        { path: '/notifications/settings', title: 'Notifications' },
                        { path: '/notifications/campaigns', title: 'Campaigns' },
                    ]}
                />
                <div className="utils">
                    <Link to="/notifications/campaigns/create">
                        <Button type="primary" size="large">
                            New campaign
                        </Button>
                    </Link>
                </div>
            </div>

            <Table
                rowKey="id"
                loading={loading}
                columns={columns}
                dataSource={data.records}
                pagination={{
                    current: data.page,
                    pageSize: data.per_page,
                    total: data.total_records,
                    showSizeChanger: true,
                    onChange: handlePageChange,
                }}
            />
        </div>
    );
}
