import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
// images
import logo from 'assets/images/logo_registered.png';
import logoIcon from 'assets/images/logo_icon.svg';
// icons
import { PrinterIcon, ColorSwatchIcon, GlobeIcon, CurrencyDollarIcon } from '@heroicons/react/outline';
import {
    Activity,
    Bag,
    TwoUsers,
    Wallet,
    Discovery,
    ArrowLeft,
    ArrowRight,
    Home,
    Category,
    Notification,
} from 'react-iconly';
import UserActivationCard from 'components/UserActivationCard';
import { useSelector } from 'react-redux';

const { Sider } = Layout;

const defaultMenu = [
    {
        title: 'Overview',
        key: 'overview',
        icon: <Activity set="light" width={24} height={24} />,
        path: '/overview',
    },
    {
        title: 'Stores',
        key: 'stores',
        icon: <Home set="light" width={24} height={24} />,
        path: '/stores',
    },
    // {
    // 	title: 'Orders',
    // 	key: 'orders',
    // 	icon: <Bag set="light" width={24} height={24} />,
    // 	path: '/orders',
    // },
    {
        title: 'Sales',
        key: 'sales',
        icon: <Bag set="light" width={24} height={24} />,
        children: [
            {
                title: 'Orders',
                key: 'orders',
                path: '/orders',
            },
            {
                title: 'Abandoned Carts',
                key: 'abndoned-carts',
                path: '/abandoned-carts',
            },
        ],
    },
    // {
    // 	title: 'Subscriptions',
    // 	key: 'subscriptions',
    // 	icon: <Chart set="light" width={24} height={24} />,
    // 	path: '/subscriptions',
    // },
    {
        title: 'Services',
        key: 'services',
        icon: <Category set="light" width={24} height={24} />,
        path: '/services',
    },
    {
        title: 'Apps',
        key: 'apps',
        icon: <ColorSwatchIcon width={24} height={24} />,
        path: '/apps',
    },
    {
        title: 'Customers',
        key: 'customer-management',
        icon: <TwoUsers set="light" width={24} height={24} />,
        // path: '/customers',
        children: [
            {
                title: 'Groups',
                key: 'customer-groups',
                path: '/customer-groups',
            },
            {
                title: 'Customers',
                key: 'customers',
                path: '/customers',
            },
        ],
    },
    {
        title: 'Inventory',
        key: 'inventory',
        icon: <PrinterIcon width={24} height={24} />,
        // path: '/inventory',
        children: [
            {
                title: 'Categories',
                key: 'categories',
                path: '/categories',
            },
            {
                title: 'Products',
                key: 'products',
                path: '/products',
            },
        ],
    },
    {
        title: 'Payments',
        key: 'payments',
        icon: <Wallet set="light" width={24} height={24} />,
        path: '/payments',
    },
    {
        title: 'Shipments',
        key: 'shipments',
        icon: <Discovery set="light" width={24} height={24} />,
        // path: '/shipments',
        children: [
            {
                title: 'Advanced Settings',
                key: 'shipment/settings',
                path: '/shipment/settings',
            },
            {
                title: 'Orders',
                key: 'shipment/orders',
                path: '/shipment/orders',
            },
        ],
    },
    {
        title: 'Notifications',
        key: 'notifications',
        icon: <Notification set="light" width={24} height={24} />,
        // path: '/notifications',
        children: [
            {
                title: 'Advanced Settings',
                key: 'notifications/settings',
                path: '/notifications/settings',
            },
            {
                title: 'Automatic',
                key: 'notifications/automatic',
                path: '/notifications/automatic',
            },
            {
                title: 'Logs',
                key: 'notifications/logs',
                path: '/notifications/logs',
            },
            {
                title: 'Campaigns',
                key: 'notifications/campaigns',
                path: '/notifications/campaigns'
            }
        ],
    },
    {
        title: 'Credits',
        key: 'credits',
        path: '/credits',
        icon: <CurrencyDollarIcon width={24} height={24} />,
    },
    {
        title: 'Domain',
        key: 'domain',
        icon: <GlobeIcon width={24} height={24} />,
        path: '/domain',
    },
];

const AppSidebar = (props) => {
    const { isCollapsed, toggleCollapse } = props;
    const [menu, setMenu] = useState(defaultMenu);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);

    const authUser = useSelector(state => state.auth.authUser);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const newMenu = [...menu];
        const notificationMenuItemIndex = newMenu.findIndex(item => item.key === 'notifications');

        const chatIframeUrl = authUser.config?.notification?.whatsapp?.settings?.chat_iframe;
        const botIframeUrl = authUser.config?.notification?.whatsapp?.settings?.bot_iframe;

        if (chatIframeUrl) {
            const chatIframeIndex = newMenu[notificationMenuItemIndex].children.findIndex(child => child.key === 'notifications/chat');
            if (chatIframeIndex < 0) {
                newMenu[notificationMenuItemIndex].children.push({
                    title: 'Live chat',
                    key: 'notifications/chat',
                    path: '/notifications/chat',
                });
            }
        }
        if (botIframeUrl) {
            const botIframeIndex = newMenu[notificationMenuItemIndex].children.findIndex(child => child.key === 'notifications/bot');
            if (botIframeIndex < 0) {
                newMenu[notificationMenuItemIndex].children.push({
                    title: 'WA Bot',
                    key: 'notifications/bot',
                    path: '/notifications/bot',
                });
            }
        }

        setMenu(newMenu);
    }, [authUser]);

    useEffect(() => {
        let childIndex = -1;
        const selectedMenuItem = menu.find((item) => {
            if (item.children) {
                let tmp = item.children.findIndex((child) => location.pathname.startsWith(child.path));

                if (tmp >= 0) {
                    childIndex = tmp;
                    return true;
                }
                return false;
            } else {
                return location.pathname.startsWith(item.path);
            }
        });

        if (selectedMenuItem) {
            if (selectedMenuItem.children) {
                setSelectedKeys([selectedMenuItem.key, selectedMenuItem.children[childIndex].key]);
                if (isCollapsed) setOpenKeys([]);
                else setOpenKeys([selectedMenuItem.key]);
            } else {
                setSelectedKeys([selectedMenuItem.key]);
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        if (isCollapsed) setOpenKeys([]);
    }, [isCollapsed]);

    const onToggleSubMenu = (key) => {
        if (openKeys.includes(key)) {
            setOpenKeys([]);
        } else {
            setOpenKeys([key]);
        }
    };

    return (
        <Sider className="app-sidebar" theme="light" collapsed={isCollapsed}>
            {isCollapsed ? (
                <img src={logoIcon} className="logo-collapsed mb-32 ml-24 mr-24" />
            ) : (
                <img src={logo} className="logo mb-32 ml-24 mr-24" />
            )}
            <UserActivationCard className={isCollapsed ? 'd-none' : ''} />

            <Menu mode="inline" className="sidebar-menu" selectedKeys={selectedKeys} openKeys={openKeys}>
                <Menu.Item
                    key={'collapse'}
                    icon={
                        isCollapsed ? (
                            <ArrowRight set="light" width={24} height={24} />
                        ) : (
                            <ArrowLeft set="light" width={24} height={24} />
                        )
                    }
                    onClick={toggleCollapse}
                    className="menu-item--collapse"
                >
                    {isCollapsed ? 'Expand Menu' : 'Collapse Menu'}
                </Menu.Item>
                {menu.map((item) => {
                    if (item.children && item.children.length) {
                        return (
                            <Menu.SubMenu
                                key={item.key}
                                icon={item.icon}
                                title={item.title}
                                onTitleClick={() => onToggleSubMenu(item.key)}
                            >
                                {item.children.map((child) => (
                                    <Menu.Item key={child.key} icon={child.icon} onClick={() => navigate(child.path)}>
                                        {child.title}
                                    </Menu.Item>
                                ))}
                            </Menu.SubMenu>
                        );
                    }
                    return (
                        <Menu.Item key={item.key} icon={item.icon} onClick={() => navigate(item.path)}>
                            {item.title}
                        </Menu.Item>
                    );
                })}
            </Menu>
        </Sider>
    );
};

export default AppSidebar;
