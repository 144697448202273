import { Button, Radio, Steps, Tabs, Typography } from 'antd';
import { useRef, useState } from 'react';
import { AiFillCheckCircle, AiOutlineMail, AiOutlineMessage, AiOutlineWhatsApp } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import { createCampaign } from '../../../requests/notification.js';
import DashboardAudience from './createCampaign/DashboardAudience.js';
import { SendTimeOptions } from './createCampaign/SendTimeOptions.js';
import UploadFile from './createCampaign/UploadFile.js';
import WhatsappTemplate from './createCampaign/WhatsappTemplate.js';

export default function CreateCampaign() {
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const data = useRef({
        service: 1,
    });
    const templateRef = useRef();

    const navigate = useNavigate();

    const handleDataChange = (newData) => {
        data.current = {
            ...data.current,
            ...newData,
        };
    };

    const handleNext = async () => {
        if (currentStep === 1 && !data.current.customers?.length) {
            return toast.error('You must choose your audience');
        } else if (currentStep === 2) {
            // whatsapp
            console.log(data);
            if (data.current.service === 1) {
                await templateRef.current.getTemplate();
            }
            if (!data.current.message) {
                return toast.error('You must write your message');
            }
        } else if (currentStep === 3) {
            if (!data.current.options) {
                return toast.error('You must choose a send date');
            }
        } else if (currentStep === 4) return;
        console.log(data.current);

        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        if (currentStep === 0) return;
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);

            await createCampaign(data.current);

            toast.success('Success');
            navigate('/notifications/campaigns');
        } catch (err) {
            toast.error(err.response?.data?.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-campaign">
            <Typography.Title level={3}>Create Campaigns</Typography.Title>
            <Steps direction="vertical" current={currentStep}>
                <Steps.Step
                    title="Select a channel"
                    description={
                        <div hidden={currentStep !== 0}>
                            <div className="select-channel">
                                <Radio.Group
                                    optionType="button"
                                    buttonStyle="solid"
                                    onChange={(e) => handleDataChange({ service: e.target.value })}
                                    defaultValue={1}
                                    options={[
                                        {
                                            value: 1,
                                            label: (
                                                <div className="channel">
                                                    <AiOutlineWhatsApp size={60} />
                                                    <span className="name">Whatsapp</span>
                                                    <span className="description"> </span>
                                                </div>
                                            ),
                                        },
                                        {
                                            value: 2,
                                            label: (
                                                <div className="channel">
                                                    <AiOutlineMessage size={60} />
                                                    <span className="name">SMS</span>
                                                    <span className="description">Coming soon</span>
                                                </div>
                                            ),
                                            disabled: true,
                                        },
                                        {
                                            value: 3,
                                            label: (
                                                <div className="channel">
                                                    <AiOutlineMail size={60} />
                                                    <span className="name">Email</span>
                                                    <span className="description">Coming soon</span>
                                                </div>
                                            ),
                                            disabled: true,
                                        },
                                    ]}
                                />
                            </div>
                            <div className="steps-nav">
                                <Button className="button-next" type="primary" onClick={handleNext}>
                                    Next
                                </Button>
                            </div>
                        </div>
                    }
                />
                <Steps.Step
                    title="Choose your audience"
                    description={
                        <div hidden={currentStep !== 1}>
                            <div className="choose-audience">
                                <Tabs
                                    defaultActiveKey="1"
                                    tabPosition="left"
                                    onChange={() => handleDataChange({ customers: null })}
                                    destroyInactiveTabPane={true}
                                >
                                    <Tabs.TabPane tab="My dashboard audience" key="1">
                                        <DashboardAudience onResult={(customers) => handleDataChange({ customers })} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Upload XLSX/CSV" key="2">
                                        <UploadFile onResult={(customers) => handleDataChange({ customers })} />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                            <div className="steps-nav">
                                <Button onClick={handlePrev}>Previous</Button>
                                <Button className="button-next" type="primary" onClick={handleNext}>
                                    Next
                                </Button>
                            </div>
                        </div>
                    }
                />
                <Steps.Step
                    title="Write your message"
                    description={
                        <div hidden={currentStep !== 2}>
                            <div className="write-message">
                                <Tabs
                                    defaultActiveKey="1"
                                    tabPosition="left"
                                    onChange={() => handleDataChange({ message: null })}
                                    destroyInactiveTabPane={true}
                                >
                                    <Tabs.TabPane tab="Template message" key="1">
                                        <WhatsappTemplate
                                            templateRef={templateRef}
                                            variables={Object.keys(data.current?.customers?.[0] || {})}
                                            onChange={(message) => handleDataChange({ message })}
                                            onFileUploaded={(file) =>
                                                handleDataChange({ files: [...(data.current.files || []), file] })
                                            }
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                            <div className="steps-nav">
                                <Button onClick={handlePrev}>Previous</Button>
                                <Button className="button-next" type="primary" onClick={handleNext}>
                                    Next
                                </Button>
                            </div>
                        </div>
                    }
                />
                <Steps.Step
                    title="Set a send date"
                    description={
                        <div hidden={currentStep !== 3} className="options">
                            <div className="send-time-options">
                                <SendTimeOptions onChange={(options) => handleDataChange({ options })} />
                            </div>
                            <div className="steps-nav">
                                <Button onClick={handlePrev}>Previous</Button>
                                <Button className="button-next" type="primary" onClick={handleNext}>
                                    Next
                                </Button>
                            </div>
                        </div>
                    }
                />
                <Steps.Step
                    title="Set live"
                    description={
                        <div hidden={currentStep !== 4}>
                            <div className="complete">
                                <Typography.Title level={5}>You are ready to go</Typography.Title>
                                <Button
                                    type="primary"
                                    icon={<AiFillCheckCircle />}
                                    onClick={handleSubmit}
                                    loading={loading}
                                >
                                    Set live
                                </Button>
                            </div>
                            <div className="steps-nav">
                                <Button onClick={handlePrev}>Previous</Button>
                            </div>
                        </div>
                    }
                />
            </Steps>
        </div>
    );
}
