import { Button, Col, Form, message, Row, Select, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    getNotificationConfig,
    getWhatsappNumbers,
    resetNotificationConfig,
    setNotificationConfig,
} from 'requests/notification';
import PhoneInputV2 from '../../../../components/Elements/PhoneInput/PhoneInputV2.js';
import { getCountries } from '../../../../requests/country.js';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';

const { Title } = Typography;

const layoutWithLabel = {
    span: 12,
};
const layoutWithoutLabel = {
    span: 12,
    offset: 12,
};

export default function GeneralSettings() {
    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState();
    const [whatsappNumbers, setWhatsappNumbers] = useState([]);
    const [countries, setCountries] = useState([]);

    const authUser = useSelector((state) => state.auth.authUser);

    const [form] = Form.useForm();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/notifications/settings/whatsapp/config' && authUser) {
            getData();
        }
    }, [location.pathname, authUser]);

    const getData = async () => {
        try {
            setLoading(true);
            const configData = await getNotificationConfig();
            setConfig(configData);

            if (authUser) {
                authUser.config.notification = configData;
            }

            const data = await getWhatsappNumbers();
            const options = data.data.map((e) => ({
                value: e.id,
                label: e.display_phone_number,
            }));

            const countriesData = await getCountries({ is_paginate: 0 });
            setCountries(countriesData.records);

            setWhatsappNumbers(options);
        } catch (err) {
            message.error(err.response?.data.message || err.response?.data.error?.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (config?.whatsapp.settings && whatsappNumbers.length) {
            form.setFieldsValue(config?.whatsapp.settings);
        }
    }, [config, form, whatsappNumbers]);

    const handleSave = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();

            if (config?.whatsapp?.settings) {
                config.whatsapp.settings = values;
            } else {
                throw new Error('Invalid config');
            }

            await setNotificationConfig(config);
        } catch (err) {
            message.error(err.response?.data.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleReset = async () => {
        try {
            setLoading(true);
            const data = await resetNotificationConfig(config);
            setConfig(data);
            form.resetFields();
            form.setFieldsValue({
                ...data?.whatsapp?.settings,
            });
        } catch (err) {
            message.error(err.response?.data.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Form
                className="general-settings"
                form={form}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                labelAlign="left"
                initialValues={config?.whatsapp?.settings}
            >
                <div className="section">
                    <Title level={5}>Select sending numbers</Title>
                    <Form.Item name={['sending_number', 'transactional']} label="Transactional">
                        <Select options={whatsappNumbers}></Select>
                    </Form.Item>
                    <Form.Item name={['sending_number', 'marketing']} label="Marketing">
                        <Select options={whatsappNumbers}></Select>
                    </Form.Item>
                </div>
                <div className="section">
                    <Title level={5}>Send a copy of message to phone number</Title>
                    <Form.List name={['send_copy', 'phone_numbers']}>
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <>
                                        <div style={{ position: 'relative' }} key={index}>
                                            <Form.Item
                                                {...field}
                                                label={index === 0 ? 'Phone numbers' : ''}
                                                wrapperCol={index ? layoutWithoutLabel : layoutWithLabel}
                                                rules={[
                                                    {
                                                        required: true,
                                                        pattern: /^[\d\s+-]*$/,
                                                        message: 'Invalid phone number',
                                                    },
                                                ]}
                                            >
                                                <PhoneInputV2 countries={countries} placeholder="Phone numbers" />
                                            </Form.Item>
                                            <AiOutlineDelete
                                                className="remove-icon"
                                                onClick={() => remove(field.name)}
                                            />
                                        </div>
                                    </>
                                ))}
                                <>
                                    <Form.Item
                                        label={!fields.length ? 'Your phone number' : ''}
                                        wrapperCol={fields.length ? layoutWithoutLabel : layoutWithLabel}
                                    >
                                        <Button onClick={() => add()}>
                                            <AiOutlinePlus />
                                            Add
                                        </Button>
                                    </Form.Item>
                                </>
                                <Form.ErrorList errors={errors} />
                            </>
                        )}
                    </Form.List>
                    <Form.Item
                        name={['send_copy', 'copy_only']}
                        label="Send to this number only"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </div>
                <Row>
                    <Col span={24} className="save-btn">
                        <Button className="mr-8" onClick={handleReset}>
                            Reset
                        </Button>
                        <Button type="primary" loading={loading} onClick={handleSave}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
