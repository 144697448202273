import { UploadOutlined } from '@ant-design/icons';
import { Button, Table, Typography, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toast';
import { read, utils } from 'xlsx';
import { csv2json } from 'utils/csv2json.js';
import { uniqueId } from 'lodash';
import { AiOutlineDownload } from 'react-icons/ai';
import sample from 'assets/files/campaign-sample-upload-file.xlsx';

export default function UploadFile({ onResult }) {
    const [customers, setCustomers] = useState();

    useEffect(() => {
        onResult?.(null);
    }, []);

    const handleUpload = (file) => {
        if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const buffer = e.target.result;
                const workbook = read(buffer);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const customers = utils.sheet_to_json(worksheet);

                for (const customer of customers) {
                    if (!customer.mobile) {
                        return toast.error("Customer's mobile number is required");
                    }
                }

                setCustomers(customers);
                onResult?.(customers);
            };
            reader.readAsArrayBuffer(file);
        } else if (file.name.endsWith('.csv')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                const customers = csv2json(text);

                for (const customer of customers) {
                    if (!customer.mobile) {
                        return toast.error("Customer's mobile number is required");
                    }
                }

                setCustomers(customers);
                onResult?.(customers);
            };
            reader.readAsText(file);
        } else {
            return toast.error('Invalid file format');
        }
        return false;
    };

    const columns = [{ title: 'Mobile', dataIndex: 'mobile' }];

    return (
        <div className="audience">
            <div className="rules">
                <Upload name="file" maxCount={1} beforeUpload={handleUpload} customRequest={() => {}}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>

                <Typography.Title className="mt-24" level={5}>
                    Upload phone numbers
                </Typography.Title>
                <ul>
                    <li>
                        The table must contain at least column "mobile" consisting of{' '}
                        <b>Phone numbers with country code starting with +</b> (e.g. +919879879871)
                    </li>
                    <li>The other columns should have header name as variable name as you like (e.g. customer_name)</li>
                    <a href={sample}>
                        Download sample file <AiOutlineDownload />
                    </a>
                </ul>
            </div>
            <div className="preview">
                {customers && (
                    <Table
                        dataSource={customers}
                        columns={columns}
                        pagination={false}
                        scroll={{ y: 480 }}
                        rowKey={(record) => uniqueId()}
                    />
                )}
            </div>
        </div>
    );
}
