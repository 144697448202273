import { Tabs } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/index.js';
import CreditHistory from './CreditHistory.js';

const creditPages = [{ path: '/credits/history', title: 'History', page: <CreditHistory /> }];

export default function CreditPage() {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!params.page) {
            navigate(creditPages[0].path);
        }
    }, [location.pathname]);

    return (
        <div className="credit-page">
            <PageTitle titles={[{ title: 'Credits', path: '/credits' }]} />
            <Tabs onChange={(key) => navigate(key)}>
                {creditPages.map((e) => (
                    <Tabs.TabPane key={e.path} tab={e.title}>
                        {e.page}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    );
}
