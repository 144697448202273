import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Select } from 'antd';
// css
import 'assets/styles/components/phone-input.scss';
import { useRef } from 'react';

const { Option } = Select;

const PhoneInputV2 = (props) => {
    const { countries, className, disabled, value, onChange, ...restProps } = props;

    const [inputValue, setInputValue] = useState();
    const [selectedCountry, setSelectedCountry] = useState();

    const inputRef = useRef();

    const countryOptions = useMemo(() => {
        return countries.map((country) => (
            <Option value={country.mobile_code} key={country.id} label={country.mobile_code} name={country.name}>
                <img src={`${process.env.REACT_APP_ASSET_URL}${country.flag}`} />
                <span>{country.name}</span>
                <span>({country.mobile_code})</span>
            </Option>
        ));
    }, [countries]);

    useEffect(() => {
        if (!countries.length) {
            return;
        }
        if (!value) {
            setInputValue(null);
            setSelectedCountry(null);
            return;
        }
        for (const country of countries) {
            if (value?.startsWith(country.mobile_code)) {
                setInputValue(value.replace(country.mobile_code, ''));
                setSelectedCountry(country.mobile_code);
                return;
            }
        }
        setSelectedCountry(null);
    }, [countries, value]);

    const handleCountrySearch = (search, option) => {
        return (option.name + option.value).toLowerCase().includes(search.toLowerCase());
    };

    const handleCountrySelect = (value) => {
        setSelectedCountry(value);
        inputRef.current.focus();
        if (selectedCountry && inputValue) {
            onChange?.(value + inputValue);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value.trim().replace(/^0+/g, '');
        setInputValue(value);
        if (!value) {
            onChange?.('');
            return;
        }
        if (selectedCountry && inputValue) {
            onChange?.(selectedCountry + value);
        }
    };

    return (
        <Input
            addonBefore={
                <Select
                    disabled={disabled}
                    value={selectedCountry}
                    dropdownClassName="phone-input-dropdown"
                    onChange={handleCountrySelect}
                    filterOption={handleCountrySearch}
                    showSearch
                    optionLabelProp="label"
                    dropdownMatchSelectWidth={220}
                >
                    {countryOptions.map((option) => option)}
                </Select>
            }
            ref={inputRef}
            className={`phone-input-wrapper ${className}`}
            value={inputValue}
            allowClear
            onChange={handleInputChange}
            disabled={disabled}
            {...restProps}
        />
    );
};

PhoneInputV2.propTypes = {
    countries: PropTypes.array,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

PhoneInputV2.defaultProps = {
    countries: [],
    className: '',
};

export default PhoneInputV2;
