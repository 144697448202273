import { Button, Table, Tabs, Tag } from 'antd';
import Search from 'antd/lib/input/Search.js';
import dayjs from 'dayjs';
import { parse, stringify } from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { FaRedo } from 'react-icons/fa';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import PageTitle from '../../components/PageTitle/index.js';
import { getSentMessageLogs, resendWhatsappMessage } from '../../requests/notification.js';

const { TabPane } = Tabs;

export default function SentMessageLogs() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentQuery, setCurrentQuery] = useState({
        page: 1,
        per_page: 10,
    });

    // const enumMessageTypes = useSelector((state) => state.config.notification_message_types);
    const enumStatuses = useSelector((state) => state.config.notification_statuses);
    const enumConfirmStatuses = useSelector((state) => state.config.confirm_link_statuses);
    const notificationServices = useSelector((state) => state.config.notification_services);

    const location = useLocation();
    const navigate = useNavigate();

    const [statuses, statusColors] = useMemo(() => {
        const statuses = {};
        const statusColors = {};
        for (const status of enumStatuses) {
            statuses[status.value] = status.display;
            statusColors[status.value] = status.color;
        }
        return [statuses, statusColors];
    }, [enumStatuses]);

    const [confirmStatuses, confirmStatusColors] = useMemo(() => {
        const statuses = {};
        const statusColors = {};
        for (const status of enumConfirmStatuses) {
            statuses[status.value] = status.display;
            statusColors[status.value] = status.color;
        }
        return [statuses, statusColors];
    }, [enumConfirmStatuses]);

    useEffect(() => {
        if (notificationServices) {
            getData();
        }
    }, [location.pathname, location.search, notificationServices]);

    const getData = async () => {
        try {
            setLoading(true);

            const query = parse(location.search.slice(1));
            if (!query.service) {
                const service = notificationServices[0]?.value;
                if (service) {
                    handleQuery({ service });
                }
                return;
            }

            setCurrentQuery(query);

            const data = await getSentMessageLogs(query);
            setData(data);
        } catch (err) {
            toast.error(err.response?.data.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleQuery = (newQuery) => {
        const query = {
            ...currentQuery,
            ...newQuery,
        };

        navigate({
            pathname: location.pathname,
            search: stringify(query),
        });
    };

    const handlePageChange = (page, per_page) => {
        handleQuery({ page, per_page });
    };

    const handleResendWhatsappMessage = async (id) => {
        try {
            setLoading(true);
            await resendWhatsappMessage(id);
            toast.success('Success');
            await getData();
        } catch (err) {
            toast.error(err.response?.data.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchQuery = (keyword) => {
        handleQuery({ keyword, page: 1 });
    };

    const handleSearchChange = (e) => {
        if (!e.target.value) {
            handleSearchQuery('');
        }
    };

    const columns = [
        {
            title: 'Order',
            dataIndex: 'order',
            render: (order) => <Link to={`/orders/${order?.id}`}>{'#' + order?.platform_order_id}</Link>,
        },
        // {
        //     title: 'Type',
        //     dataIndex: 'type',
        //     render: (value) => types[value],
        // },
        {
            title: 'Event',
            dataIndex: ['event', 'name'],
            render: (value, record) => (
                <>
                    <span className="mr-8">{value}</span>
                    {record.details?.has_cod_confirm_link && record.order?.params?.offline_order_confirm?.status && (
                        <Tag color={confirmStatusColors[record.order?.params?.offline_order_confirm?.status]}>
                            {confirmStatuses[record.order?.params?.offline_order_confirm?.status]}
                        </Tag>
                    )}
                </>
            ),
        },
        {
            title: 'Sending number',
            dataIndex: ['wa_sending_number', 'display_phone_number'],
            render: (number) => <span className="nowrap">{number}</span>,
        },
        {
            title: 'Receive number',
            dataIndex: 'receive_number',
        },
        {
            title: 'Sent at',
            dataIndex: 'sent_at',
            render: (time) => (
                <div>
                    <span className="nowrap">{dayjs(time).format('YYYY-MM-DD')}</span>
                    <span> </span>
                    <span className="nowrap">{dayjs(time).format('HH:mm')}</span>
                </div>
            ),
        },
        {
            title: 'Cost',
            dataIndex: ['details', 'credit_cost'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (value, record) => (
                <Tag title={record.wam_id || record.details?.error_message} color={statusColors[value]}>
                    {statuses[value] || value}
                </Tag>
            ),
        },
        {
            title: 'Action',
            render: (record, index) => (
                <div className="action">
                    <Button onClick={() => handleResendWhatsappMessage(record.id)}>
                        <FaRedo />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="sent-message-logs">
            <PageTitle
                titles={[
                    { path: '/notifications/settings', title: 'Notifications' },
                    { path: '/notifications/logs', title: 'Logs' },
                ]}
            />
            <div className="log-utils">
                <Tabs
                    activeKey={currentQuery.service}
                    destroyInactiveTabPane={true}
                    onChange={(service) => handleQuery({ service })}
                >
                    {notificationServices &&
                        notificationServices.map((service) => <TabPane tab={service?.display} key={service?.value} />)}
                </Tabs>
                <Search
                    className="search"
                    placeholder="Type here to search"
                    allowClear
                    onChange={handleSearchChange}
                    onSearch={handleSearchQuery}
                />
            </div>
            <Table
                rowKey="id"
                loading={loading}
                columns={columns}
                dataSource={data.records}
                expandable={{
                    expandedRowRender: (record) => (
                        <ReactJson className="details-viewer" src={record.details} name="details" collapsed={false} />
                    ),
                }}
                pagination={{
                    current: data.page,
                    pageSize: data.per_page,
                    total: data.total_records,
                    showSizeChanger: true,
                    onChange: handlePageChange,
                }}
            />
        </div>
    );
}
