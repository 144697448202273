import api from 'utils/api';

export const getOrderSummary = () => {
    return new Promise((resolve, reject) => {
        return api.get('/merchant/summary/orders').then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}

export const getTransactionSummary = () => {
    return new Promise((resolve, reject) => {
        return api.get('/merchant/summary/transactions').then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}