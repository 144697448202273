import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, Outlet, useLocation } from 'react-router-dom';
// layouts
import AppLayout from 'layout/app';
import CheckoutLayout from 'layout/checkout';
// actions
import { getAuthUserAction as getAuthUser } from 'redux/actions/auth';
import { getConfigAction as getConfig } from 'redux/actions/config';
// components
import Loading from 'components/Loading';
import Signin from 'routes/auth/Signin';
import Signup from 'routes/auth/Signup';
import ForgotPassword from 'routes/auth/ForgotPassword';
import ResetPassword from 'routes/auth/ResetPassword';
import UserVerification from 'routes/auth/UserVerification';
import UserSetToken from 'routes/auth/UserSetToken';
import Error404 from 'routes/error/Error404';
import Overview from 'routes/overview';
import Customer from 'routes/customers';
import Error401 from 'routes/error/Error401';
import OrderList from 'routes/order/OrderList';
import Onboarding from 'routes/onboarding/Onboarding';
import Pricing from 'routes/pricing';
import Services from 'routes/service';
import StoreCreateForm from 'routes/store/StoreCreateForm';
import StoreList from 'routes/store/StoreList';
import StoreDetail from 'routes/store/StoreDetail';
import StoreSyncProgress from 'routes/store/StoreSyncProgress';
import PaymentAdvancedSettings from 'routes/payment/advanced-setting';
import CustomerGroupList from 'routes/customerGroups';
import OrderDetail from 'routes/order/OrderDetail';
import Products from 'routes/product/ProductList';
import ProductDetail from 'routes/product/ProductDetail';
import CategoryList from 'routes/category/CategoryList';
import ServiceDetail from 'routes/service/ServiceDetail';
import ShipmentAdvancedSettings from 'routes/shipment/ShipmentAdvancedSettings';
import ShipmentOrders from 'routes/shipment/ShipmentOrders';
import ShipmentTracking from 'routes/shipment/ShipmentTracking';
import IncompletedOrderList from 'routes/order/IncompletedOrderList';
import Checkout from 'routes/checkout';
import CheckoutResult from 'routes/checkout/CheckoutResult';
import SubscriptionList from 'routes/subscription/SubscriptionList';
import NotificationsAdvancedSettings from 'routes/notification/AdvancedSettings';
import NotificationsAutomatic from 'routes/notification/Automatic';
import AppList from 'routes/app/AppList';
import AppDetail from 'routes/app/AppDetail';
import SentMessageLogs from 'routes/notification/SentMessageLogs';
import TransactionList from 'routes/transaction/TransactionList';
import Domain from 'routes/domain';
import CreditPage from 'routes/credit/CreditPage.js';
import LiveChat from 'routes/notification/LiveChat.js';
import Bot from 'routes/notification/Bot';
import Campaigns from 'routes/notification/Campaigns.js';
import CreateCampaign from 'routes/notification/campaigns/CreateCampaign.js';
import CampaignLogs from 'routes/notification/campaigns/CampaignLogs.js';

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const authUser = useSelector((state) => state.auth.authUser);

    if (authUser) {
        if (!authUser.status) return <Navigate to="/otp-verification" replace />;

        return children;
    }

    // store origin path to localstorage, redirect to it after login success
    localStorage.setItem('originPath', location.pathname);

    return <Navigate to="/signin" state={{ from: location }} replace />;
};

const AppRoutes = () => {
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        async function getData() {
            try {
                await dispatch(getConfig());
                await dispatch(getAuthUser());
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, []);

    if (loading) return <Loading />;

    return (
        <Routes>
            {/* Error pages */}
            <Route path="*" element={<Error404 />} />
            <Route exact path="/401" element={<Error401 />} />
            <Route exact path="/404" element={<Error404 />} />
            {/* Registration pages */}
            <Route exact path="/signin" element={<Signin />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/setToken" element={<UserSetToken />} />
            <Route exact path="/otp-verification" element={<UserVerification />} />
            <Route exact path="/shipment/tracking" element={<ShipmentTracking />} />
            <Route
                element={
                    <ProtectedRoute>
                        <CheckoutLayout>
                            <Outlet />
                        </CheckoutLayout>
                    </ProtectedRoute>
                }
            >
                <Route exact path="/pricing" element={<Pricing />} />
                <Route exact path="/checkout" element={<Checkout />} />
                <Route exact path="/checkout/result" element={<CheckoutResult />} />
            </Route>
            {/* Dashboard */}
            <Route
                element={
                    <ProtectedRoute>
                        <AppLayout>
                            <Outlet />
                        </AppLayout>
                    </ProtectedRoute>
                }
            >
                <Route exact path="/" element={<Navigate to="/overview" />} />
                <Route exact path="/overview" element={<Overview />} />
                <Route exact path="/customers" element={<Customer />} />
                <Route exact path="/orders" element={<OrderList />} />
                <Route exact path="/abandoned-carts" element={<IncompletedOrderList />} />
                <Route exact path="/orders/:id" element={<OrderDetail />} />
                <Route exact path="/onboarding" element={<Onboarding />} />
                {/* <Route exact path="/pricing" element={<Pricing />} /> */}
                <Route exact path="/services" element={<Services />} />
                <Route exact path="/services/:id" element={<ServiceDetail />} />
                <Route exact path="/stores" element={<StoreList />} />
                <Route exact path="/stores/:id" element={<StoreDetail />} />
                <Route exact path="/stores/create" element={<StoreCreateForm />} />
                <Route exact path="/stores/:id/sync-progress" element={<StoreSyncProgress />} />
                <Route exact path="/customer-groups" element={<CustomerGroupList />} />
                <Route exact path="/products" element={<Products />} />
                <Route exact path="/products/:id" element={<ProductDetail />} />
                <Route exact path="/categories" element={<CategoryList />} />
                <Route exact path="/shipment/settings" element={<ShipmentAdvancedSettings />} />
                <Route exact path="/shipment/orders" element={<ShipmentOrders />} />
                <Route exact path="/payments" element={<PaymentAdvancedSettings />} />
                <Route exact path="/subscriptions" element={<SubscriptionList />} />
                {/* <Route exact path="/checkout" element={<Checkout />} />
				<Route exact path="/checkout/result" element={<CheckoutResult />} /> */}
                <Route path="/notifications/settings" element={<NotificationsAdvancedSettings />} />
                <Route path="/notifications/settings/:service" element={<NotificationsAdvancedSettings />} />
                <Route path="/notifications/settings/:service/:tab" element={<NotificationsAdvancedSettings />} />
                <Route path="/notifications/automatic" element={<NotificationsAutomatic />} />
                <Route path="/notifications/automatic/:type" element={<NotificationsAutomatic />} />
                <Route path="/notifications/logs" element={<SentMessageLogs />} />
                <Route path="/notifications/campaigns" element={<Campaigns />} />
                <Route path="/notifications/campaigns/:id" element={<CampaignLogs />} />
                <Route path="/notifications/campaigns/create" element={<CreateCampaign />} />
                <Route path="/notifications/chat" element={<LiveChat />} />
                <Route path="/notifications/bot" element={<Bot />} />
                <Route path="/credits" element={<CreditPage />} />
                <Route path="/credits/:page" element={<CreditPage />} />
                <Route path="/apps" element={<AppList />} />
                <Route path="/apps/:id" element={<AppDetail />} />
                <Route path="/transactions" element={<TransactionList />} />
                <Route path="/domain" element={<Domain />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
