import { Table } from 'antd';
import dayjs from 'dayjs';
import { parse, stringify } from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';
import { getCreditTransaction as getCreditTransactions } from '../../requests/credit.js';

export default function CreditHistory() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentQuery, setCurrentQuery] = useState({
        page: 1,
        per_page: 10,
    });

    const location = useLocation();
    const navigate = useNavigate();

    const typesEnum = useSelector((state) => state.config.credit_transaction_types);

    const typesMap = useMemo(() => {
        const types = {};
        for (const type of typesEnum) {
            types[type.value] = type.display;
        }
        return types;
    }, [typesEnum]);

    useEffect(() => {
        getData();
    }, [location.pathname, location.search]);

    const getData = async () => {
        try {
            setLoading(true);

            const query = parse(location.search.slice(1));
            setCurrentQuery(query);

            const data = await getCreditTransactions(query);
            setData(data);
        } catch (err) {
            toast.error(err.response?.data.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleQuery = (newQuery) => {
        const query = {
            ...currentQuery,
            ...newQuery,
        };

        navigate({
            pathname: location.pathname,
            search: stringify(query),
        });
    };

    const handlePageChange = (page, per_page) => {
        handleQuery({ page, per_page });
    };

    const columns = [
        {
            title: 'Time',
            dataIndex: 'created_at',
            render: (time) => dayjs(time).format('YYYY-MM-DD HH:mm'),
        },
        {
            title: 'Value',
            dataIndex: 'value',
            render: (value) => {
                return value < 0 ? (
                    <span style={{ color: 'red' }}>{value}</span>
                ) : (
                    <span style={{ color: 'green' }}>+{value}</span>
                );
            },
        },
        {
            title: 'Balance',
            dataIndex: 'balance_after',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (value) => typesMap[value],
        },
    ];

    return (
        <div className="sent-message-logs">
            <Table
                rowKey="id"
                loading={loading}
                columns={columns}
                dataSource={data.records}
                pagination={{
                    current: data.page,
                    pageSize: data.per_page,
                    total: data.total_records,
                    showSizeChanger: true,
                    onChange: handlePageChange,
                }}
            />
        </div>
    );
}
