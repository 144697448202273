import { useEffect, useState } from "react";
import { useNavigate, Navigate, Link, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Col, Row, Form, Divider, Typography, Input, Checkbox } from "antd";
import { toast } from "react-toast";
import FacebookButton from "components/SocialAuthenticationButtons/FacebookButton";
import GoogleButton from "components/SocialAuthenticationButtons/GoogleButton";
// images
import logo from 'assets/images/logo_registered.png';
import authenticationImage from 'assets/images/authentication_graphic.png';
// requests
import { loginAction as login } from "redux/actions/auth";

const { Title } = Typography;

const Signin = () => {
    const [data, setData] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);

    const [formRef] = Form.useForm();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const isError = Number(searchParams.get('error'));

    const user = useSelector(state => state.auth.authUser);

    useEffect(() => {
        if (isError === 1) navigate('/401', { replace: true });
    }, [isError]);

    useEffect(() => {
        if (user) {
            // check if origin path is existed or not
            let redirectPath = localStorage.getItem('originPath');
            if (!redirectPath || redirectPath.startsWith('/signin') || redirectPath.startsWith('/signup')) redirectPath = '/overview';
            if (user.status === 0) redirectPath = '/otp-verification';

            // remove origin path
            localStorage.removeItem('originPath');

            navigate(redirectPath, { replace: true });
        }
    }, [user]);

    const onSubmit = async (formData) => {
        try {
            setLoading(true);
            await dispatch(login(formData));
        } catch (error) {
            toast.error("An error occurs. Please try again.");
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="authentication-container">
            <Row className="authentication-header--wrapper" justify="space-between" align="middle">
                <img src={logo} className="logo" alt="logo" />
                <div>
                    <span className="mr-24">New user?</span>
                    <Link to='/signup'>
                        <Button type="primary" size="large">Signup</Button>
                    </Link>
                </div>
            </Row>
            <Row className="authentication-content--wrapper" gutter={48} justify="space-between" align="middle">
                <Col xs={24} sm={24} md={12} lg={10}>
                    <Card className="authentication-content">
                        <Title level={3}>Sign in to your Sellonboard account</Title>
                        {/* Form */}
                        <Form
                            className="mt-32"
                            layout="vertical"
                            autoComplete="off"
                            form={formRef}
                            initialValues={data}
                            onFinish={onSubmit}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true }]}
                            >
                                <Input placeholder='Email' />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true }]}
                            >
                                <Input.Password placeholder='Password' />
                            </Form.Item>
                            <div>
                                <Link to='/forgot-password'>Forgot your password?</Link>
                            </div>
                            <Button htmlType='submit' type="primary" size="large" className='w-100 mt-16' loading={loading}>Sign In</Button>
                            <Row justify="space-between" align="middle" className="mt-24">
                                <Checkbox>Keep me signed in</Checkbox>
                                <Link to='/signup'>Create a new Sellonboard account</Link>
                            </Row>
                            <Divider>or</Divider>
                            <FacebookButton title="Signin via Facebook" />
                            <GoogleButton className="mt-16" title="Signin via Google" />
                        </Form>
                    </Card>
                </Col>
                <Col className="authentication-block--img" xs={24} sm={24} md={12} lg={12}>
                    <Title level={3}>How Sellonboard can help?</Title>
                    <p className="mt-24">We enjoy adapting our strategies to offer every client the best solutions that are at the forefront of the industry.</p>
                    <img src={authenticationImage} className="mt-24" width="80%" />
                    <div className="mt-32">Need help? We are just a click away. <a href="#">Contact Us</a></div>
                </Col>
            </Row>
        </div>
    )
}

export default Signin;