import { DatePicker, Input, Radio, Space } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import { BsCalendar2Check, BsCalendar2Week } from 'react-icons/bs';
import { toast } from 'react-toast';
import moment from 'moment';

export const SendTimeOptions = ({ onChange }) => {
    const [sendTime, setSendTime] = useState();
    const [config, setConfig] = useState();
    const [time, setTime] = useState();
    const [days, setDays] = useState(2);
    const [messages, setMessages] = useState(1000);

    useEffect(() => onChange?.(sendTime), [onChange, sendTime]);
    useEffect(() => sendTime?.type === 'schedule' && setSendTime({ ...sendTime, time }), [time]);
    useEffect(() => {
        if (config && !Number(config.value)) {
            return toast.error('Invalid input value');
        }
        setSendTime({ ...sendTime, config });
    }, [config]);
    useEffect(() => config?.type === 'distribute_per_day' && setConfig({ ...config, value: days }), [days]);
    useEffect(() => config?.type === 'daily_limit' && setConfig({ ...config, value: messages }), [messages]);

    const handleDaysBlur = (e) => {
        if (sendTime.type === 'schedule' && sendTime.option?.type === 'distribute_per_day') {
            if (!Number(e.target.value)) {
                setSendTime(null);
                toast.error('Invalid input value');
            }
        }
    };

    const handleMessagesBlur = (e) => {
        if (sendTime.type === 'schedule' && sendTime.option?.type === 'daily_limit') {
            if (!Number(e.target.value)) {
                setSendTime(null);
                toast.error('Invalid input value');
            }
        }
    };

    return (
        <div className="options">
            <Radio.Group>
                <Space direction="vertical">
                    <Radio.Button value="now" onClick={() => setSendTime({ type: 'now' })}>
                        <div className="send-time-option">
                            <div className="icon">
                                <BsCalendar2Check size={48} />
                            </div>
                            <div className="label">
                                <h1>Send now</h1>
                            </div>
                        </div>
                    </Radio.Button>
                    <Radio.Button
                        value="schedule"
                        onClick={() => setSendTime({ type: 'schedule', time: moment().utc().format(), config })}
                    >
                        <div className="send-time-option">
                            <div className="icon">
                                <BsCalendar2Week size={48} />
                            </div>
                            <div className="label">
                                <h1>Schedule</h1>
                                <DatePicker
                                    className={sendTime?.type !== 'schedule' && 'hidden'}
                                    style={{ width: 480 }}
                                    showTime
                                    allowClear={false}
                                    defaultValue={moment()}
                                    onChange={(value) => setTime(value?.utc().format())}
                                />
                            </div>
                        </div>
                    </Radio.Button>
                </Space>
            </Radio.Group>
            <div className="ml-24 mt-8" hidden={sendTime?.type !== 'schedule'}>
                <Radio.Group optionType="default" value={config?.type}>
                    <Space direction="vertical">
                        <Radio
                            value="distribute_per_day"
                            onClick={() => setConfig({ type: 'distribute_per_day', value: days })}
                        >
                            Distribute campaign messages in
                            <Input
                                className="ml-8 mr-8"
                                size="small"
                                type="number"
                                style={{ width: 64 }}
                                value={days}
                                onChange={(e) => setDays(Number(e.target.value))}
                                onBlur={handleDaysBlur}
                            />
                            days
                        </Radio>
                        <Radio value="daily_limit" onClick={() => setConfig({ type: 'daily_limit', value: messages })}>
                            Send daily
                            <Input
                                className="ml-8 mr-8"
                                size="small"
                                type="number"
                                style={{ width: 64 }}
                                value={messages}
                                onChange={(e) => setMessages(Number(e.target.value))}
                                onBlur={handleMessagesBlur}
                            />
                            messages
                        </Radio>
                    </Space>
                </Radio.Group>
            </div>
        </div>
    );
};
