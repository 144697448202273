import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { generateServiceName } from 'utils/common';
import OrderFulfillmentStatusDropdown from 'components/OrderFulfillmentStatusDropdown';
import OrderPaymentStatusDropdown from 'components/OrderPaymentStatusDropdown';

const OrderListView = ({ records, isTableLoading, pagination, selectedRecords, onChangeTable, onSelectRecords }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const config = useSelector(state => state.config);
    
    const columns = [
        {
            title: 'Order ID',
            key: 'order_number',
            dataIndex: 'order_number',
            render: (text, record) => (
                <Link to={`/orders/${record.id}`}>{text}</Link>
            )
        },
        {
            title: 'Date',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (text) => (
                <span>{new Date(text).toLocaleString('en-GB')}</span>
            )
        },
        {
            title: 'Payment',
            key: 'payment_status',
            dataIndex: 'payment_status',
            width: 250,
            render: (text, record) => (
                <div>
                    <div>{record.payment_method}</div>
                    <OrderPaymentStatusDropdown
                        orderId={record.id}
                        defaultValue={text}
                    />
                </div>
            )
        },
        {
            title: 'Fulfillment',
            key: 'fulfillment_status',
            dataIndex: 'fulfillment_status',
            width: 250,
            render: (text, record) => (
                <div>
                    <div>{record.shipping_method}</div>
                    <OrderFulfillmentStatusDropdown
                        orderId={record.id}
                        defaultValue={text}
                    />
                </div>
            )
        },
        {
            title: 'Customer',
            key: 'shipping_person_name',
            dataIndex: 'shipping_person_name',
            width: 180,
            render: (text, record) => {
                const addressItems = [record.shipping_person_street, record.shipping_person_city, record.shipping_person_state, record.shipping_person_portal_code];
                if (record.shipping_person_country) addressItems.push(record.shipping_person_country.name);
                const formattedAddress = addressItems.filter(item => item).join(', ');

                return (
                    <div>
                        <div>{record.shipping_person_name}</div>
                        <div><small>{formattedAddress}</small></div>
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            key: 'total',
            dataIndex: 'total',
            render: (text, record) => (
                <span>{record.currency} {record.total}</span>
            )
        },
        {
            title: 'Gateway / Payment ID',
            key: 'payment_tx_id',
            dataIndex: 'payment_tx_id',
            render: (text, record) => (
                <div>
                    {
                        record.transaction ? (
                            <div><strong>{generateServiceName(config.service_types, record.transaction.gateway)}</strong></div>
                        ) : null
                    }
                    <div>{text}</div>
                </div>
            )
        },
    ];

    useEffect(() => {
        console.log(selectedRecords)
        setSelectedRowKeys(selectedRecords);
    }, [selectedRecords]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            onSelectRecords(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({}),
    };

    return (
        <div>
            <Table
                rowSelection={rowSelection}
                rowKey='id'
                columns={columns}
                dataSource={records}
                loading={isTableLoading}
                pagination={pagination}
                onChange={onChangeTable}
            />
        </div>
    )
}

export default OrderListView;